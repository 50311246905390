"use client";

import { Popover } from "@typeform/embed-react";
import { usePathname, useSearchParams } from "next/navigation";
import { useWindowSize } from "react-use";
import { MIN_PC_WIDTH } from "~/const/window";

const SurveyPopover: React.FunctionComponent = () => {
  const searchParams = useSearchParams();
  const path = usePathname();
  const queryString = searchParams.toString();
  const pathName = queryString ? `${path}?${queryString}` : path;
  const { width: windowWidth } = useWindowSize();
  const widthSize = windowWidth;
  const isPC = widthSize > MIN_PC_WIDTH;

  return !isPC || isPC ? null : (
    <Popover
      id="uTnmMqVp"
      buttonColor="#2391DC"
      customIcon="https://images.typeform.com/images/bMzQC9pButCX"
      hidden={{
        utm_source: `${process.env.NEXT_PUBLIC_FRONT_URL + pathName}`,
        utm_campaign: "popover",
      }}
    ></Popover>
  );
};

export default SurveyPopover;
